<template>
  <v-list
    dense
    nav
    shaped
  >
    <v-layout
      v-for="section in sections"
      :key="section.title"
      class="d-block">
      <v-list-item
        v-if="!section.items"
        :href="section.href ? section.href : undefined"
        :to="section.name ? {name: section.name} : undefined"
        :target="section.is_target_blank ? '_blank' : ''"
      >
        <v-list-item-icon>
          <v-icon :title="section.title"
                  slot="prependIcon"
                  class="ml-1"
                  :color="secondaryColor"
          >{{section.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="subtitle-1"
                          :style="secondaryColorStyle"
        >
          {{ section.title }}
          <i class="fas fa-xs fa-external-link-alt ml-2"
             :color="secondaryColor"
             v-if="section.is_target_blank"
          />
        </v-list-item-title>
      </v-list-item>
      <v-list-group
        v-if="section.items"
        no-action
        color=""
        :value="$router.currentRoute.path.startsWith(section.sectionPath)"
      >
        <v-icon :title="section.title"
                slot="prependIcon"
                class="ml-1"
                :color="secondaryColor"
        >{{section.icon}}</v-icon>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1" :style="secondaryColorStyle"
                               v-text="section.title"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          :color="secondaryColorStyle"
          v-for="subItem in section.items"
          :key="subItem.title"
          :to="subItem.name ? {name: subItem.name} : undefined"
          :href="subItem.href ? subItem.href : undefined"
        >
          <v-list-item-content>
            <v-list-item-title class="subtitle-2" v-text="subItem.title" :style="secondaryColorStyle"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="subItem.icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-layout>
  </v-list>
</template>
<script>
import { IS_CAC } from '@/variables.js'
export default {
  name: 'NavigationDrawerList',
  props: {
    sections: Array
  },
  computed: {
    secondaryColor () {
      return IS_CAC ? this.$vuetify.theme.currentTheme.secondary : '#fff'
    },
    secondaryColorStyle () {
      return `color: ${this.secondaryColor}`
    }
  }
}
</script>
<style scoped>
  .no_hover:hover {
    text-decoration: none;
  }
</style>
