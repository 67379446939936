<template>
  <v-list>
    <v-list-item
      v-for="module in modules"
      :key="module.code_name"
      :to="module.frontend_path ? {name: module.frontend_path} : undefined"
      class="no_hover">
      <v-list-item-action>
        <v-icon>{{ module.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ module.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
     <v-list-item
      v-for="(tab, index) in extendedModulesExtra"
      :key="index"
      :to="tab.frontend_path ? {name: tab.frontend_path} : undefined"
      :href="tab.href ? tab.href : undefined"
      :target="tab.is_target_blank ? '_blank' : ''"
      class="no_hover">
      <v-list-item-action>
        <v-icon>{{ tab.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ tab.name }}
          <i class="fas fa-xs fa-external-link-alt ml-2" v-if="tab.is_target_blank"/>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>

</template>

<script>
import { SUPPORT_URL } from '@/variables'
import { mapState } from 'vuex'

export default {
  name: 'AppDrawerList',
  computed: {
    ...mapState(['modules', 'modulesExtra', 'userProfile']),
    extendedModulesExtra () {
      let extendedModulesExtra = [{ name: 'Soporte', href: SUPPORT_URL, icon: 'fa-info' }]
      return extendedModulesExtra.concat(this.modulesExtra)
    }
  }
}
</script>

<style scoped>
  .no_hover:hover {
    text-decoration: none;
  }

</style>
