<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="display-1 text-center mb-5">{{ title }}</div>
        <div class="subtitle-1 text-center mb-5">{{ subtitle }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center" justify="center">
        <v-btn class="save" color="accent" @click="onRedirect">
          <v-icon left>{{ goToIcon }}</v-icon>
          {{ goToLabel }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/**
 * NotFoundElement Component:
 * Can be used to render a basic "Not found" message with a redirect, when the id provided in the URL doesn't exist
 *
 * To be used in a component's Layout: (Example at AgreementDetail.vue)
 *      <Layout
 *        :isElementNotFound="isElementNotFound"
 *        :notFoundProps="notFoundProps"
 *        ... >
 *
 * - isElementNotFound is a boolean that, when false, renders the default slot as usual but,
 *    when true, renders the NotFoundElement component with the given configuration
 * - notFoundProps is an object with the title, subtitle, label, icon and redirect configuration props
 *    (passed directly from the Layout)
 */

export default {
  name: 'NotFoundElement',
  data () {

  },
  props: {
    title: {
      type: String,
      default: function () { return this.$t('general.notFoundIdElement') }
    },
    subtitle: {
      type: String,
      default: function () { return this.$t('general.notFoundIdElement') }
    },
    goToLabel: {
      type: String,
      default: function () { return this.$t('general.toIndexView') }
    },
    goToIcon: {
      type: String,
      default: 'fa-home'
    },
    redirectTo: {
      type: Object,
      default: () => ({ name: 'cosmos' })
    }
  },
  methods: {
    onRedirect () {
      this.$router.push(this.redirectTo)
    }
  }
}
</script>
