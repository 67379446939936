<template>
  <div>
    <vue-headful :title="tabTitle" />
    <v-app id="inspire">
    <un-notifications-queue/>

    <v-navigation-drawer
      app
      v-show="isLoggedIn"
      dark
      class="elevation-3"
      color="primary"
      style="z-index: 10;"
      width="310px"
      permanent
      expand-on-hover
    >
      <v-list class="mt-n2">
        <v-list-item class="primary darken-1">

          <v-list-item-icon tile>
            <v-icon class="ml-1">fa-bars</v-icon>
          </v-list-item-icon>

          <v-list-item-content class="font-weight-bold title">
            <v-list-item-title
              :style="isCac ? 'color: #3F2021' : undefined">{{$t('meta.appName')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <NavigationDrawerList :sections="this.navigationDrawerSections"/>
        <template v-slot:append>
          <div class="my-2 mx-2">
             <v-img :src="APPLICATION_LOGO" class="logo" />
          </div>

          <v-divider/>
          <div class="my-2 love">
            <MadeWithLove />
          </div>
      </template>
      </v-navigation-drawer>

    <TopBar :title="topBarTitleName" :activeBackButton="activeBackButton">
      <slot name="actionBar"></slot>
    </TopBar>

    <v-content class="grey lighten-5">
      <slot name="breadcrumb" />
      <v-container :style="displayAllScreen ? 'max-width: 97%;' : undefined">
        <v-row align="center" justify="center">
          <v-col :cols="allScreen">
            <div v-if="!isElementNotFound">
              <slot></slot>
            </div>
            <div v-else>
              <NotFoundElement v-bind="notFoundProps"/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
  </div>
</template>

<script>
import MadeWithLove from '@/lib/unlogin/components/MadeWithLove'
import NavigationDrawerList from './NavigationDrawerList'
import { mapGetters, mapActions, mapState } from 'vuex'
import { APPLICATION_LOGO, IS_CAC, IS_PNG } from '@/variables'
import TopBar from './TopBar'
import UnNotificationsQueue from '@/lib/unnotificationsqueue/UnNotificationsQueue'
import NotFoundElement from './NotFoundElement'

export default {
  name: 'Layout',
  components: {
    NotFoundElement,
    TopBar,
    NavigationDrawerList,
    MadeWithLove,
    UnNotificationsQueue
  },
  props: {
    topBarTitle: String,
    displayAllScreen: {
      type: Boolean,
      default: true
    },
    sections: {
      type: Array,
      required: true
    },
    baseLayoutInfoUrl: {
      type: String,
      required: false
    },
    activeBackButton: Boolean,
    isElementNotFound: {
      type: Boolean,
      default: false
    },
    notFoundProps: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      app_links: [
        { title: 'Admin Site', path: '/unadmin' }
      ]
    }
  },
  computed: {
    ...mapState(['navigationDrawerSections', 'clientName']),
    ...mapGetters(['isLoggedIn', 'user']),
    miniDrawer: {
      set (value) {
        this.changeMiniSideBar(value)
      },
      get () {
        return this.$store.state.miniSideBar
      }
    },
    APPLICATION_LOGO: () => APPLICATION_LOGO,
    allScreen () {
      return this.displayAllScreen ? undefined : 'xl-10 lg-11 md-12 sm-1'
    },
    isCac () {
      return IS_CAC
    },
    tabTitle () {
      return this.topBarTitle || this.clientName
    },
    topBarTitleName () {
      return this.topBarTitle
    }
  },
  created () {
    this.getUser()
    this.getCosmosModuleByUser()
    if (this.baseLayoutInfoUrl !== '' && this.baseLayoutInfoUrl !== undefined && this.baseLayoutInfoUrl !== null) {
      this.getBaseLayoutInfo(this.baseLayoutInfoUrl)
    } else {
      this.changeNavigationDrawerSections(this.sections)
    }
  },
  methods: {
    ...mapActions(['changeMiniSideBar', 'getBaseLayoutInfo', 'changeNavigationDrawerSections', 'getCosmosModuleByUser', 'getUser']),
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
        .catch(err => alert(err))
    },
    login: function () {
      this.$router.push('/login')
    },
    capitalizeStr: function (value) {
      return value !== null && IS_PNG ? value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : value
    }
  }
}
</script>

<style scoped>
.love {
  width: 300px;
}

.logo {
  margin-left: 105px;
  margin-bottom: 25px;
  width: 100px;
  height: 100px;
  filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.8));
}
</style>
