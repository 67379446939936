<template>
  <v-app-bar
    app
    class="grey lighten-3 elevation-1"
    style="z-index: 6"
    dense
  >
    <v-btn icon class="hidden-xs-only" v-show="activeBackButton" v-on:click="navigateBack()">
      <v-icon>fa-arrow-left</v-icon>
    </v-btn>

    <v-toolbar-title>{{topBarTitle}}</v-toolbar-title>

    <!-- Here goes the page custom action bar -->
    <v-flex class="d-flex">
      <slot></slot>
    </v-flex>

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" @click="$router.push({ name: 'cosmos' })">
          <v-icon>fa-home</v-icon>
        </v-btn>
      </template>
    </v-menu>

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>fa-th</v-icon>
        </v-btn>
      </template>
      <AppDrawerList/>
    </v-menu>

    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>fa-user</v-icon>
        </v-btn>
      </template>
      <v-list>

        <v-list-item v-if="user" key="username">
          <v-list-item-icon>
            <v-icon>fa-user</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{user}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user" key="logout" v-on:click="logout()">
          <v-list-item-icon>
            <v-icon>fa-sign-out-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('general.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!user" key="login" v-on:click="login()">
          <v-btn text icon color="primary" class="white--text">
            <v-icon>fa-sign-in-alt</v-icon>

          </v-btn>
          Login
        </v-list-item>

      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import AppDrawerList from './AppDrawerList'
import { AUTH0_ENABLED } from '@/variables'

export default {
  name: 'TopBar',
  components: { AppDrawerList },
  props: {
    title: String,
    activeBackButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user']),
    topBarTitle: function () {
      return this.title ? this.title : this.$route.name
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => {
          if (AUTH0_ENABLED) {
            this.$auth.logout()
          }
          this.$router.push('/login')
        })
        .catch(err => {
          alert(err)
        })
    },
    login: function () {
      this.$router.push('/login')
    },
    navigateBack: function () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
  /* .mygradient {
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, var(--v-secondary-lighten2) 100%);
    background: var(--v-secondary-lighten3);
  }
   */
</style>
